/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// mui
import { Box, Card, CardHeader } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

export default function PracticeHistoryChart(props) {
  const series = [
    {
      name: 'Accuracy',
      data: props.data?.map((x) => x.report?.avgAccuracy || 0.1)?.reverse(),
      type: 'bar',
    },
    {
      name: 'Total time',
      type: 'area',
      fill: 'gradient',
      data: props.data?.map((x) => x.report?.totalTime || 0.1)?.reverse(),
    },
    {
      name: 'Avg time per question',
      data: props.data?.map((x) => x.report?.avgTimePerQuestion || 0.1)?.reverse(),
      type: 'line',
    },
  ];

  const options = useChart({
    chart: {
      type: 'line',
    },
    fill: { type: series.map((i) => i.fill) },

    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      enabledOnSeries: [0],
      labels: {
        formatter: (value) => `${value?.toFixed(0)}%`,
      },
      formatter: (value) => `${value?.toFixed(0)}%`,
      style: {
        colors: ['#fff'],
        fontSize: 10,
      },
      background: { enabled: false },
    },
    xaxis: {
      categories: props.data?.map((x) => moment(x.createdAt).format('DD MMM HH:mm A'))?.reverse(),
    },
    yaxis: [
      {
        opposite: true,
        seriesName: 'Accuracy',
        title: {
          text: 'Accuracy',
        },
        max: 100,
        min: 0,
        labels: {
          formatter: (value) => `${value?.toFixed(0)}%`,
        },
        tickAmount: 4,
      },
      {
        seriesName: 'Total time',
        title: {
          text: 'Total time',
        },
        labels: {
          formatter: (value) => `${value?.toFixed(1)}s`,
        },
      },
      {
        seriesName: 'Avg time per question',
        show: false,
        opposite: true,
        title: {
          text: '',
        },
        max: Math.max(props.data?.map((x) => x.totalTime)),
        labels: {
          formatter: (value) => `${value?.toFixed(1)}s`,
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        show: true,
      },
    },

    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    plotOptions: { bar: { columnWidth: '30%' } },
  });

  return (
    <Card>
      <CardHeader title={'Practice History'} subheader="Up to 5 last attempts" />

      <Box sx={{ p: 3, py: 0 }} dir="ltr">
        <ReactApexChart series={series} options={options} height={360} />
      </Box>
    </Card>
  );
}

PracticeHistoryChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
