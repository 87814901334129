import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';

// @mui
import { Box, Link, Drawer, Typography, Avatar, Stack, Button } from '@mui/material';
// mock
// import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components

import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';

// sections
import Profile from '../../../sections/common/nav/Profile';

//
import navConfig from './config';

import { AppContext } from '../../../App';
import { useAuth } from '../../../services/auth';

// ----------------------------------------------------------------------

const NAV_WIDTH = 140;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { user: account, setUser } = useContext(AppContext);
  const { logout } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLogout = () => {
    logout();
    setUser(null);
    navigate('/home', { replace: true });
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo notext />
      </Box>

      <NavSection data={navConfig(account)} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        {account && <Profile account={account} setUser={setUser} />}

        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Button variant="contained" fullWidth color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  if (
    pathname?.includes('/practice/subject/session') ||
    pathname?.includes('/practice/subject/result') ||
    pathname?.includes('/practice/sat/session') ||
    pathname?.includes('/practice/sat/result')
  ) {
    return null;
  }

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
