import { Navigate, useRoutes } from 'react-router-dom';

// Routes
import MainRoutes from './MainRoutes';
import StudentPortalRoutes from './StudentPortalRoutes';
import TeacherPortalRoutes from './TeacherPortalRoutes';

import Page404 from '../pages/Page404';
import Page403 from '../pages/Page403';

import { Roles } from '../constants/roles';

// ----------------------------------------------------------------------

export default function Router({ user }) {
  const Layout = !user ? MainRoutes : user.role === Roles.student ? StudentPortalRoutes : TeacherPortalRoutes;

  const routes = useRoutes([
    ...Layout(user),

    { path: '403', element: <Page403 /> },
    { path: '404', element: <Page404 /> },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
