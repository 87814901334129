// ----------------------------------------------------------------------

export default function Select(theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 600,
          },
        },
      },
    },
  };
}
