import * as PropTypes from 'prop-types';
import * as moment from 'moment';
import { Stack, Typography } from '@mui/material';

export default function DateCell({ value }) {
  return (
    <Stack>
      <Typography variant="body2" noWrap>
        {moment(value).format('MMM DD YYYY')}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {moment(value).format('HH:mm')}
      </Typography>
    </Stack>
  );
}

DateCell.propTypes = {
  value: PropTypes.string,
};
