/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// mui
import { Box, Button, Card, CardHeader, Chip, Stack, Typography } from '@mui/material';

// components
import { Table, TableProvider, useTableContext } from '../../../../components/table';
import Modal from '../../../../components/Dialog';
import LatexFormula from '../../../../components/latex-formula';

import { formatDuration } from '../../../../utils/formatTime';

function Questions({ questions = [], teacher = false, title }) {
  const { loadLocalData } = useTableContext();
  const [popupQuestionShow, setPopupQuestionShow] = useState(false);
  const [popupQuestion, setPopupQuestion] = useState(false);

  useEffect(() => {
    loadLocalData(questions);
  }, [questions]);

  const onQuestionContentClick = (row) => {
    setPopupQuestionShow(true);
    setPopupQuestion(row);
  };

  const handlePopupClose = () => {
    setPopupQuestionShow(false);
    setPopupQuestion(null);
  };

  const columns = [
    {
      id: 'question',
      label: 'Question',
      disableSort: true,
      onClick: onQuestionContentClick,
      sx: { cursor: 'zoom-in', whiteSpace: 'break-spaces' },
      renderCell: ({ picture, formula, textQuestion, topic, subject, level }, _, idx) => (
        <Box sx={{ pb: 2 }}>
          {teacher && (
            <Stack sx={{ display: 'flex', flexDirection: 'row', mb: 2, gap: 1 }}>
              <Chip label={`${idx + 1}.`} size="small" />
              {level && <Chip label={level} size="small" />}
              {topic && <Chip label={topic} size="small" />}
              <Chip label={subject} size="small" />
            </Stack>
          )}
          {picture ? (
            <img style={{ height: '100%', maxHeight: 100 }} src={picture} alt="" />
          ) : formula ? (
            <LatexFormula formula={formula} />
          ) : (
            textQuestion
          )}
        </Box>
      ),
    },
    {
      id: 'correct',
      label: 'Answer',

      renderCell: ({ userAnswer }) => userAnswer || 'N/A',
      sx: ({ correct }) => ({ color: correct ? 'green' : 'red' }),
    },
    {
      id: 'answer',
      label: 'Correct Answer',
      disableSort: true,
    },
    {
      id: 'time',
      label: 'Time',
      renderCell: ({ time }) => formatDuration(time, 1),
      sx: ({ time, expectedTime }) => ({ color: time < expectedTime ? 'green' : 'red' }),
    },
  ].filter((x) => {
    if (!teacher && x.id === 'answer') {
      return false;
    }
    return true;
  });

  const rowActions = [
    {
      label: 'Open in new tab',
      icon: 'eva:diagonal-arrow-right-up-outline',
      onClick: (row) => {
        window.open(`/teacher/questions/${row.questionId}`, '_blank');
      },
      showInCell: true,
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <Card>
        <CardHeader title={title || 'Questions'} sx={{ mb: 2 }} />
        <Table columns={columns} hover rowActions={teacher && rowActions} hideMenuButton />
      </Card>

      <Stack sx={{ padding: 3 }} direction={'row'} justifyContent={'space-between'}>
        <Modal open={popupQuestionShow} handleClose={handlePopupClose}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={5} pb={0} mb={0}>
            <Typography variant="h6" sx={{}}></Typography>
            <Button color="inherit" onClick={handlePopupClose}>
              Close
            </Button>
          </Stack>
          {popupQuestion?.picture ? (
            <img
              src={popupQuestion.picture}
              alt={'Could not load the question. Try to re-add the picture'}
              style={{ maxHeight: 600, margin: '15px auto' }}
              aria-hidden="true"
            />
          ) : (
            <Stack sx={{ p: 2, alignItems: 'center', minHeight: 300, justifyContent: 'center' }}>
              <LatexFormula formula={popupQuestion?.formula} />

              <Typography fontSize={24} letterSpacing={1} sx={{ whiteSpace: 'pre-line' }}>
                {popupQuestion?.textQuestion}
              </Typography>
            </Stack>
          )}
        </Modal>
      </Stack>
    </div>
  );
}

export default function QuestionsWrapper(props) {
  return (
    <TableProvider>
      <Questions {...props} />
    </TableProvider>
  );
}

Questions.propTypes = {
  questions: PropTypes.array,
  teacher: PropTypes.bool,
  title: PropTypes.string,
};
