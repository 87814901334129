import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
// import { NavLink as RouterLink } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Tab, Tabs, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { Roles } from '../../../constants/roles';
import { AppContext } from '../../../App';
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 64;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${NAV_WIDTH}px)`,
  },
  width: 70,
  top: 20,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { pathname } = useLocation();
  const { user } = useContext(AppContext);
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (pathname?.includes('/sat/test')) {
    return null;
  }

  return (
    <StyledRoot
      sx={{
        display: { lg: 'none' },
      }}
    >
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
          {/* <Typography color="black" variant="h5">
            Hey, {user?.name}
          </Typography> */}
          {/* {user?.role === Roles.student && (
            <Tabs value={value} onChange={handleChange} aria-label="nav tabs">
              <Tab sx={{ fontSize: '16px' }} label="Dashboard" component={Link} to="/dashboard" />
              <Tab sx={{ fontSize: '16px' }} label="Tests" component={Link} to="/dashboard/app" />
            </Tabs>
          )} */}
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* {user?.role !== 'student' && <AccountPopover />} */}
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
