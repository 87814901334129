import { useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';

import { Helmet } from 'react-helmet-async';

import Iconify from '../../../components/iconify';

export default function InstructionsPage() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title> Instructions | SAT Decoded App </title>
      </Helmet>

      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Iconify icon="eva:file-text-outline" width={48} height={48} color="primary.main" />
                <Typography variant="h4" gutterBottom sx={{ mt: 5 }}>
                  Subject
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center' }} color={'text.secondary'}>
                  Practice specific subjects. <br /> Get instant feedback on your answers.
                </Typography>
                <Button variant="outlined" sx={{ mt: 4 }} onClick={() => navigate('/student/practice')}>
                  Select
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Iconify icon="eva:file-text-outline" width={48} height={48} color="primary.main" />
                <Typography variant="h4" gutterBottom sx={{ mt: 5 }}>
                  SAT Test
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center' }} color={'text.secondary'}>
                  Practice SAT Test to improve your score. <br /> Get instant feedback on your answers.
                </Typography>
                <Button variant="contained" sx={{ mt: 5 }} onClick={() => navigate('/student/sat/test')}>
                  Start Now
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
