import * as React from 'react';
import PropTypes from 'prop-types';

// mui
import MuiDialog from '@mui/material/Dialog';

export default function Dialog({ open, handleClose, children, ...rest }) {
  return (
    <MuiDialog open={open} onClose={handleClose} maxWidth="lg" fullWidth {...rest}>
      {children}
    </MuiDialog>
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.any,
};
