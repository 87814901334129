import React from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Grid, Stack } from '@mui/material';

const Header = () => {
  const navigate = useNavigate();

  const sendCTAClick = () => {
    ReactGA.event({ eventCategory: 'CTA', eventAction: 'click', eventLabel: 'Request Account' });
  };

  return (
    <Box component={'header'} sx={{ p: 5 }}>
      <Container>
        <Grid container spacing={3} alignItems={'center'}>
          <Grid item>
            <Stack direction="row" spacing={2} alignItems={'center'}>
              <Box
                component="img"
                src="/assets/logo-text.png"
                sx={{ height: 60, cursor: 'pointer' }}
                alt="SAT Decoded App"
                onClick={() => {
                  navigate('/home');
                }}
              />
            </Stack>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }} />
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/login')}
              sx={{
                display: { xs: 'none', md: 'inline' },
              }}
            >
              Sign In
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                sendCTAClick();
                navigate('/contact');
              }}
            >
              Request Account
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Header;
