import PropTypes from 'prop-types';
import Latex from 'react-latex-next';

export default function LatexFormula({ formula }) {
  if (formula) {
    return <Latex>{`$${formula}$`}</Latex>;
  }
  return null;
}

LatexFormula.propTypes = {
  formula: PropTypes.string,
};
