import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import StudentPortalLayout from '../layouts/StudentPortal';

// v2
import StudentDashboardPage from '../pages/StudentPortal/v2/DashboardPage';
import SATPage from '../pages/StudentPortal/v2/SAT/SATPage';

import PracticeSubjectPage from '../pages/StudentPortal/v2/Practice/PracticeSubjectPage';
import PracticeTestPage from '../pages/StudentPortal/v2/Practice/PracticeTestPage';
import PracticeViewPage from '../pages/StudentPortal/v2/Practice/PracticeViewPage';
import InstructionsPage from '../pages/StudentPortal/v2/Instructions';

const PracticePage = lazy(() => import('../pages/StudentPortal/v2/Practice/PracticePage'));
const SessionInstructionsPage = lazy(() => import('../pages/StudentPortal/v2/Practice/Session/Instructions'));

const StudentPortalSubjectsPage = lazy(() => import('../pages/StudentPortal/v2/Practice/SubjectsPage'));

const SATSessionPage = lazy(() => import('../pages/StudentPortal/v2/Practice/Session/SATSession'));
const SubjectSessionPage = lazy(() => import('../pages/StudentPortal/v2/Practice/Session/SubjectSession'));

const StudentPortalSATResultPage = lazy(() => import('../pages/StudentPortal/v2/Practice/SAT/SATResultPage'));

const SubjectResultPage = lazy(() => import('../pages/StudentPortal/v2/Practice/Session/SubjectResult'));
const SATResultPage = lazy(() => import('../pages/StudentPortal/v2/Practice/Session/SATResult'));

const LessonsPage = lazy(() => import('../pages/StudentPortal/v2/Lessons'));

export default function Routes(user) {
  return [
    {
      path: '/',
      element: <StudentPortalLayout />,
      children: [
        // v2
        { element: <Navigate to={user.type === 'sat' ? '/student/practice' : '/student/practice'} />, index: true },
        {
          path: 'student/dashboard',
          element: <StudentDashboardPage />,
        },

        // Practice
        {
          path: 'student/practice',
          element: <PracticePage />,
        },

        // SAT
        {
          path: 'student/practice/sat/instructions',
          element: <SessionInstructionsPage sat />,
        },
        {
          path: 'student/practice/sat/session/:data',
          element: <SATSessionPage />,
        },
        {
          path: 'student/practice/sat/result/:id',
          element: <SATResultPage />,
          // element: <StudentPortalSATResultPage />,
        },

        // Subject
        {
          path: 'student/practice/subjects',
          element: <StudentPortalSubjectsPage />,
        },
        {
          path: 'student/practice/subject/instructions/:subject',
          element: <SessionInstructionsPage />,
        },
        {
          path: 'student/practice/subject/session/:subject',
          element: <SubjectSessionPage />,
        },
        {
          path: 'student/practice/subject/result/:id',
          element: <SubjectResultPage />,
        },

        // {
        //   path: 'student/sat',
        //   element: <SATPage />,
        // },
        // {
        //   path: 'student/sat/test',
        //   element: <SATTestPage />,
        // },
        // {
        //   path: 'student/practice/subject/:subject',
        //   element: <PracticeSubjectPage />,
        // },
        // {
        //   path: 'student/practice/:id/test',
        //   element: <PracticeTestPage />,
        // },
        // {
        //   path: 'student/practice/:id/view',
        //   element: <PracticeViewPage />,
        // },
        {
          path: 'student/instructions',
          element: <InstructionsPage />,
        },
        {
          path: 'student/lessons',
          element: <LessonsPage />,
        },
      ],
    },
  ];
}
