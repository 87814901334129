import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import Iconify from '../iconify';

import { useTableContext } from './TableContext';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  padding: theme.spacing(0, 1, 0, 3),
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export default function TableToolbar({ title, filtersConfig, onFilterChange }) {
  const { filters, setFilters, filterEnabled } = useTableContext();

  if (!title && !filtersConfig?.length) {
    return null;
  }
  return (
    <StyledRoot>
      {title && <Typography variant="h6">{title}</Typography>}
      {filtersConfig?.map((filter) => {
        if (filter.type === 'search') {
          return (
            <OutlinedInput
              key={filter.name}
              variant="outlined"
              placeholder={`Search by ${filter.label}...`}
              value={filters[filter.name] || ''}
              onChange={(e) => onFilterChange(filter.name, e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
          );
        }
        if (filter.type === 'select' || filter.type === 'multi-select') {
          const multiple = filter.type === 'multi-select';
          const value = filters[filter.name] || (multiple ? [] : '');
          return (
            <FormControl sx={{ width: 300, mr: 1 }} key={filter.name}>
              <InputLabel id={filter.label}>{filter.label}</InputLabel>
              <Select
                onChange={(e) => onFilterChange(filter.name, e.target.value)}
                input={<OutlinedInput label={filter.label} />}
                multiple={multiple}
                value={value}
                MenuProps={MenuProps}
                {...(multiple && {
                  renderValue: (selected) => selected.join(', '),
                })}
              >
                {filter.options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      m: 1,
                      p: 1,
                      pl: multiple ? 0 : 1,
                      fontWeight: filters[filter.name]?.indexOf(option.value) > -1 ? 'bold' : 'normal',
                      borderRadius: '6px',
                    }}
                  >
                    {multiple && <Checkbox checked={filters[filter.name]?.indexOf(option.value) > -1} />}
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
        return null;
      })}
      <Box flexGrow={1} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2 }}>
        {filterEnabled && (
          <Tooltip title="Clear all filters">
            <IconButton onClick={() => setFilters({})} color="error">
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </StyledRoot>
  );
}

TableToolbar.propTypes = {
  title: PropTypes.string,
  filtersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf(['search', 'select', 'multi-select']),
      options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    })
  ),
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
  clearFilters: PropTypes.func,
  filterEnabled: PropTypes.bool,
};
