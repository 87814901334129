import * as _ from 'lodash';

export const removeNumbers = (str) => _.startCase(str?.replace(/[0-9]/g, ''));

export const mapMasteryLevel = (level) => {
  switch (level) {
    case 0:
      return 'Beginner';
    case 1:
      return 'Intermediate';
    case 2:
      return 'Master';
    default:
      return 'Beginner';
  }
};
