/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// mui
import { alpha, Box, CardContent, Grow, Typography } from '@mui/material';
// components
import { useChart } from '../../../components/chart';
import { formatDuration } from '../../../utils/formatTime';

export default function SATTestHistory(props) {
  const series = [
    {
      name: 'SAT Score',
      data: props.data?.map((x) => x.estimatedSATScore || 0.1),
      type: 'bar',
    },
    {
      name: 'Total time',
      type: 'area',
      fill: 'gradient',
      data: props.data?.map((x) => x.totalTime || 0.1),
    },
    // {
    //   name: 'Avg time per question',
    //   data: props.data?.map((x) => x.avgTimePerQuestion || 0.1),
    //   type: 'line',
    // },
  ];

  const options = useChart({
    chart: {
      type: 'line',
    },
    fill: { type: series.map((i) => i.fill) },

    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      enabledOnSeries: [0],
      labels: {
        formatter: (value) => `${value?.toFixed(0)}`,
      },
      formatter: (value) => `${value?.toFixed(0)}`,
      style: {
        colors: ['#fff'],
        fontSize: 10,
      },
      background: { enabled: false },
    },
    xaxis: {
      categories: props.data?.map((x) => moment(x.time).format('DD MMM HH:mm A')),
    },
    yaxis: [
      {
        opposite: true,
        seriesName: 'SAT Score',
        title: {
          text: 'SAT Score',
        },
        max: 800,
        min: 200,
        labels: {
          formatter: (value) => `${value?.toFixed(0)}`,
        },
        tickAmount: 4,
      },
      {
        seriesName: 'Total time',
        title: {
          text: 'Total time',
        },
        labels: {
          formatter: (value) => `${formatDuration(value)}`,
        },
      },
      {
        seriesName: 'Avg time per question',
        show: false,
        opposite: true,
        title: {
          text: '',
        },
        max: Math.max(props.data?.map((x) => x.totalTime)),
        labels: {
          formatter: (value) => `${formatDuration(value)}`,
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        show: true,
      },
    },

    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    plotOptions: { bar: { columnWidth: '16%' } },
  });

  return (
    <Grow in timeout={1000}>
      <Box
        sx={{
          width: '100%',
          borderRadius: '14px',
          border: (theme) => `1px solid ${alpha(theme.palette.grey[500], 0.22)}`,
          p: 3,
        }}
      >
        <Typography variant="subtitle1" align="left">
          SAT History
        </Typography>
        <CardContent>
          <ReactApexChart series={series} options={options} height={400} />
        </CardContent>
      </Box>
    </Grow>
  );
}

SATTestHistory.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
