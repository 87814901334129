import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, ListSubheader, styled } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

const StyleListSubheader = styled(ListSubheader)(() => ({
  backgroundColor: 'unset',
}));

export default function NavSection({ data = [], ...other }) {
  const mainSection = data.filter((x) => x.section === 'main' && !x.hidden);
  const satList = data.filter((x) => x.section === 'sat' && !x.hidden);
  const adminList = data.filter((x) => x.section === 'admin' && !x.hidden);

  return (
    <Box {...other}>
      {satList.length > 0 && (
        <>
          {/* <StyleListSubheader>SAT</StyleListSubheader> */}
          <List disablePadding sx={{ p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {satList.map((item) => (item.hidden ? null : <NavItem key={item.title} item={item} />))}
          </List>
        </>
      )}
      {satList.length > 0 && mainSection.length > 0 && <StyleListSubheader>Main</StyleListSubheader>}
      <List disablePadding sx={{ p: 1 }}>
        {mainSection.map((item) => (item.hidden || item.admin ? null : <NavItem key={item.title} item={item} />))}
      </List>

      {adminList.length > 0 && (
        <>
          <StyleListSubheader>Admin</StyleListSubheader>
          <List disablePadding sx={{ p: 1 }}>
            {adminList.map((item) => (item.hidden ? null : <NavItem key={item.title} item={item} />))}
          </List>
        </>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  // return (
  //   <Box
  //     component={RouterLink}
  //     to={path}
  //     sx={{
  //       display: 'flex',
  //       alignItems: 'center',
  //       flexDirection: 'column',
  //       justifyContent: 'center',
  //       width: 40,
  //       '&.active': {
  //         color: 'text.primary',
  //         bgcolor: 'action.selected',
  //         fontWeight: 'fontWeightBold',
  //       },
  //     }}
  //   >
  //     <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
  //   </Box>
  // );

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        width: 100,
        textAlign: 'center',
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
