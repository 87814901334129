/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Cookies from 'es-cookie';

export default function LoginSuccessPage() {
  const queryParams = useParams();

  useEffect(() => {
    Cookies.set('token', queryParams.token);
    localStorage.setItem('token', queryParams.token);

    window.location = '/';
  }, []);
  return <></>;
}
