import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

export default function TextFieldControl({ meta, input, ...rest }) {
  return (
    <>
      <TextField
        name={input.name}
        value={input.value}
        onChange={(e) => {
          if (rest._onChange) {
            rest._onChange(e);
          }
          input.onChange(e);
        }}
        type={input.type}
        InputProps={{
          inputProps: {
            step: rest.step,
          },
        }}
        inputProps={{ type: rest.type, step: rest.step }}
        error={(!!meta.error || !!meta.submitError) && meta.touched}
        helperText={
          (meta.error || meta.submitError) && meta.touched ? meta.error || meta.submitError : rest.helperText || ''
        }
        {...rest}
      />
    </>
  );
}

TextFieldControl.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
};
