import { Suspense, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

// @mui
import { Card, CardContent, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
// sections
import Header from '../../sections/common/header';
//
import { AppContext } from '../../App';
// import { Roles } from '../../constants/roles';
import Nav from './Nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

const StyledRoot = styled(Container)({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // marginTop: APP_BAR_MOBILE,
  // marginTop: theme.spacing(8),
  // marginBottom: theme.spacing(8),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  // padding: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    // padding: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------------

export default function StudentPortalLayout() {
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const { user } = useContext(AppContext);
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/student', title: 'Student Portal' });
  }, []);

  const showHeader = true || !pathname?.includes('/practice/subject/session');

  return (
    <StyledRoot maxWidth="xl">
      {showHeader && <Header user={user} onOpenNav={() => setOpen(true)} />}
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Suspense>
          <Outlet />
        </Suspense>
      </Main>
    </StyledRoot>
  );
}
