import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  MenuItem,
  Menu,
  IconButton,
  TableSortLabel,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';

import { useTableContext } from './TableContext';

import Iconify from '../iconify';
import TableToolbar from './TableToolbar';
import DateCell from './DateCell';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

const Table = ({ title, columns, filtersConfig, rowActions, hideMenuButton, onRowClick, hidePagination }) => {
  const { data, loading, pagination, setPagination, loadData, filterEnabled, handleRequestSort, handleFilterChange } =
    useTableContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({ ...pagination, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  return (
    <div>
      <TableToolbar title={title} filtersConfig={filtersConfig} onFilterChange={handleFilterChange} />

      <TableContainer>
        <MuiTable>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.alignRight ? 'right' : 'left'}
                  sortDirection={pagination.orderBy === column.id ? pagination.order : false}
                >
                  <TableSortLabel
                    hideSortIcon
                    active={pagination.orderBy === column.id}
                    direction={pagination.orderBy === column.id ? pagination.order : 'asc'}
                    onClick={(e) => handleRequestSort(e, column.id)}
                    disabled={column.disableSort}
                  >
                    {column.label}
                    {pagination.orderBy === column.id ? (
                      <Box sx={{ ...visuallyHidden }}>
                        {pagination.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
              {rowActions && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && (
              <>
                {data.docs.map((row, rowIdx) => (
                  <TableRow key={row._id || rowIdx} onClick={() => onRowClick && onRowClick(row)}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        sx={{ ...(typeof column.sx === 'function' ? column.sx(row) : column.sx) }}
                        onClick={column.onClick ? () => column.onClick(row) : undefined}
                      >
                        {column.date ? (
                          <DateCell value={row[column.id]} />
                        ) : column.renderCell ? (
                          column.renderCell(row, column, rowIdx)
                        ) : (
                          row[column.id]
                        )}
                      </TableCell>
                    ))}
                    {rowActions && (
                      <>
                        <TableCell align="right">
                          {rowActions
                            .filter((action) => action.showInCell && !action.hidden?.(row))
                            .map((action) => (
                              <Tooltip key={action.label} title={action.label} placement="top">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={async () => {
                                    action.onClick(row);
                                    if (action.reloadAfter) await loadData();
                                  }}
                                >
                                  <Iconify icon={action.icon} />
                                </IconButton>
                              </Tooltip>
                            ))}
                          {hideMenuButton === true ? null : (
                            <IconButton size="large" color="inherit" onClick={(event) => handleMenuOpen(event, row)}>
                              <Iconify icon="eva:more-vertical-fill" />
                            </IconButton>
                          )}
                        </TableCell>

                        <Menu
                          id="table-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl) && selectedRow?._id === row._id}
                          onClose={handleMenuClose}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              py: 0,
                              width: 140,
                              '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                              },
                            },
                          }}
                        >
                          {rowActions
                            .filter((action) => !action.hidden?.(selectedRow || {}))
                            .map((action) => (
                              <MenuItem
                                key={action.label}
                                onClick={async () => {
                                  await action.onClick(selectedRow);
                                  if (action.reloadAfter) await loadData();
                                  handleMenuClose();
                                }}
                                sx={{ color: action.color || 'inherit' }}
                              >
                                {action.icon && <Iconify icon={action.icon} sx={{ mr: 2 }} />}
                                {action.label}
                              </MenuItem>
                            ))}
                        </Menu>
                      </>
                    )}
                  </TableRow>
                ))}
              </>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={columns.length + (rowActions ? 1 : 0)} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {!loading && filterEnabled && data.totalDocs === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + (rowActions ? 1 : 0)} align="center">
                  <Typography variant="body2" color="textSecondary">
                    No data found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!loading && !filterEnabled && data.totalDocs === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + (rowActions ? 1 : 0)} align="center">
                  <img src="/assets/icons/no-data.svg" alt="No data" style={{ margin: '0 auto' }} />
                  <Typography variant="body2" color="textSecondary">
                    No data
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
        {hidePagination ? null : (
          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={data.totalDocs}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default Table;

Table.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      alignRight: PropTypes.bool,
      sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      date: PropTypes.bool,
      renderCell: PropTypes.func,
      onClick: PropTypes.func,
    })
  ).isRequired,
  filtersConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf(['search', 'select', 'multi-select']),
      options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    })
  ),
  rowActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  onRowClick: PropTypes.func,
  hideMenuButton: PropTypes.bool,
  hidePagination: PropTypes.bool,
};
