import * as Cookies from 'es-cookie';

import { useAxios } from './axiosService';

import { Roles } from '../constants/roles';

export const useAuth = () => {
  const { axios } = useAxios();
  const authorise = async () => {
    try {
      const res = await axios.get(`/api/v2/auth/profile?timestamp=${Date.now()}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return res.data;
    } catch (err) {
      logout();
      return null;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    Cookies.remove('token');
  };

  const login = async (body) => {
    const res = await axios.post('/api/v2/auth/login', body);
    localStorage.setItem('token', res.data.token);
    Cookies.set('token', res.data.token);
    return res;
  };
  return { authorise, logout, login };
};

export const collectCookies = () => {
  const token = Cookies.get('token');
  if (token) {
    localStorage.setItem('token', token);
  }
};

export const hasPermissions = (user, roles) => {
  if (!user) {
    return false;
  }
  if (user.role === Roles.admin) {
    return true;
  }

  return roles.includes(user.role);
};

// const authorise = () => {
// const { axios } = useAxios();
// };
