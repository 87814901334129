import { Button, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

export default function CookiesPopup() {
  const [cookiesPopup, setCookiesPopup] = useState(false);

  useEffect(() => {
    const _cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!_cookiesAccepted) {
      setCookiesPopup(true);
    }
  }, []);

  if (!cookiesPopup) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={cookiesPopup}
      onClose={() => setCookiesPopup(false)}
      message="We use cookies to ensure you get the best experience on our website."
      action={
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            setCookiesPopup(false);
            localStorage.setItem('cookiesAccepted', true);
          }}
        >
          Accept Cookies
        </Button>
      }
    />
  );
}
