import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Box, Typography, Button, Stack, Grid, useTheme } from '@mui/material';

import Iconify from '../../components/iconify';

import { bgGradient } from '../../utils/cssStyles';

import useResponsive from '../../hooks/useResponsive';

const HeroSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const sendCTAClick = () => {
    ReactGA.event({ eventCategory: 'CTA', eventAction: 'click', eventLabel: 'Request Account' });
  };

  const isDesktop = useResponsive('up', 'md');

  return (
    <Grid item xs={12} sm={10}>
      <Box
        sx={{
          width: '100%',
          // height: { xs: '100%', md: 'calc(100vh - 140px)' },
          p: 3,
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
          gap: { xs: 3, md: 6 },
          alignItems: { xs: 'start', md: 'center' },
          justifyContent: { xs: 'center', md: 'center' },
        }}
      >
        <Stack
          sx={{
            [theme.breakpoints.down('md')]: {
              alignItems: 'center',
            },
          }}
        >
          {!isDesktop ? (
            <Typography variant="h2" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
              Ace The SAT Math Without Studying Hours Everyday Guaranteed!
            </Typography>
          ) : (
            <Typography variant="h2" component="h1" gutterBottom>
              Ace The SAT Math Without Studying Hours Everyday Guaranteed!
            </Typography>
          )}
          {!isDesktop ? (
            <Typography variant="body1" component="p" color="text.secondary" sx={{ fontSize: 18, textAlign: 'center' }}>
              Transform your SAT preparation with personalized practice, detailed analytics, and unlimited testing.
            </Typography>
          ) : (
            <Typography variant="body1" component="p" color="text.secondary" sx={{ fontSize: 18 }}>
              Transform your SAT preparation with personalized <br /> practice, detailed analytics, and unlimited
              testing.
            </Typography>
          )}

          <Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 3 }}
              onClick={() => {
                sendCTAClick();
                navigate('/contact');
              }}
            >
              Try Now For Free
            </Button>
          </Box>
          <Stack
            sx={{
              mt: 2.5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              '& > *': {
                mx: 0.5,
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Iconify icon="eva:checkmark-circle-2-outline" color="text.secondary" />
              <Typography variant="body2" component="p" color="text.secondary" sx={{ ml: 1, fontSize: 13 }}>
                14 days free trial
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Iconify icon="eva:checkmark-circle-2-outline" color="text.secondary" />
              <Typography variant="body2" component="p" color="text.secondary" sx={{ ml: 1, fontSize: 13 }}>
                No credit card required
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Iconify icon="eva:checkmark-circle-2-outline" color="text.secondary" />
              <Typography variant="body2" component="p" color="text.secondary" sx={{ ml: 1, fontSize: 13 }}>
                Free support included
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack>
          <Box
            sx={{
              ...bgGradient({
                startColor: theme.palette.primary.lighter,
                endColor: theme.palette.violet.lighter,
              }),
              p: { xs: 2, md: 3 },
              borderRadius: { xs: 'calc(12px + 16px)', md: 'calc(12px + 16px)' },
            }}
          >
            <img src="/assets/images/landing/hero.png" alt="SAT Decoded App" style={{ borderRadius: 12 }} />
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
};

export default HeroSection;
