import React from 'react';

import { Box, Typography, Grid, Stack, Link } from '@mui/material';

const Footer = () => (
  <Box component={'footer'} sx={{ p: 2 }}>
    <Grid container spacing={3} alignItems={'center'} justifyContent={'center'}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'center'}>
          <Box component="img" src="/assets/logo.png" sx={{ height: 24, width: 24 }} alt="SAT Decoded App" />
          <Typography variant="body2" color="textSecondary">
            © 2024 SAT Decoded App
          </Typography>
          <Link href="/contact" color="textSecondary">
            Contact Us
          </Link>
          <Typography variant="body2" color="textSecondary" component={'a'} href="mailto:info@thesatdecoded.com">
            info@thesatdecoded.com
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  </Box>
);

export default Footer;
