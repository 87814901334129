import { useState } from 'react';
import PropTypes from 'prop-types';

// mui
import {
  Button,
  Card,
  CardHeader,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify/Iconify';
import Modal from '../../../components/Dialog';
import { formatDuration } from '../../../utils/formatTime';

export default function SubjectAccuracy({ data }) {
  const [showModal, setShowModal] = useState(false);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('avgAccuracy');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = [...data].sort((a, b) => {
    if (orderBy === 'avgAccuracy') {
      return order === 'asc' ? a.avgAccuracy - b.avgAccuracy : b.avgAccuracy - a.avgAccuracy;
    }
    return order === 'asc' ? a.avgTime - b.avgTime : b.avgTime - a.avgTime;
  });

  return (
    <>
      <Card>
        <CardHeader title={'Subject Efficiency'} />

        <Stack padding={3} gap={3} pb={2}>
          {data.slice(0, 4)?.map((subject) => (
            <Stack gap={1} key={subject.subject}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="body2">{subject.subject}</Typography>
                <Typography variant="body2">{subject.avgAccuracy?.toFixed(0)}%</Typography>
              </Stack>
              <LinearProgress
                value={subject.avgAccuracy}
                variant="determinate"
                color={subject.avgAccuracy < 100 ? 'error' : 'success'}
                sx={{
                  height: 10,
                  borderRadius: 5,
                }}
              />
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 2 }} justifyContent={'flex-end'} direction={'row'}>
          <Button
            variant="text"
            color="inherit"
            size="small"
            endIcon={<Iconify icon={'eva:arrow-ios-forward-outline'} width={18} />}
            onClick={() => setShowModal(true)}
          >
            View All
          </Button>
        </Stack>
      </Card>

      <Modal open={showModal} handleClose={() => setShowModal(false)} maxWidth="lg">
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={5} pb={0} mb={0}>
          <Typography variant="h6" sx={{}}>
            Subject Efficiency Details
          </Typography>
          <Button color="inherit" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Stack>
        <Stack
          sx={{ p: 5, minHeight: 300, alignItems: 'flex-start', justifyContent: 'flex-start', overflow: 'scroll' }}
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Subject</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'avgTime'}
                      direction={orderBy === 'avgTime' ? order : 'asc'}
                      onClick={() => handleRequestSort('avgTime')}
                    >
                      Avg Time (s)
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'avgAccuracy'}
                      direction={orderBy === 'avgAccuracy' ? order : 'asc'}
                      onClick={() => handleRequestSort('avgAccuracy')}
                    >
                      Avg Accuracy
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.subject}</TableCell>
                    <TableCell>
                      <Typography variant="caption">{formatDuration(row.avgTime)}</Typography>
                    </TableCell>
                    <TableCell>
                      <LinearProgress
                        variant="determinate"
                        value={row.avgAccuracy}
                        color={row.avgAccuracy < 100 ? 'error' : 'success'}
                      />
                      <Typography variant="caption">{row.avgAccuracy.toFixed(0)}%</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Modal>
    </>
  );
}

SubjectAccuracy.propTypes = {
  data: PropTypes.array.isRequired,
};
