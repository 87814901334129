import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSearchParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { Grid } from '@mui/material';

import HormoziStyle from '../../sections/main/HormoziStyle';
import Header from '../../sections/main/Header';
import Hero from '../../sections/main/Hero';
import Universities from '../../sections/main/Universities';
import HowItWorks from '../../sections/main/HowItWorks';
import Stats from '../../sections/main/Stats';
import Join from '../../sections/main/Join';
import Pricing from '../../sections/main/Pricing';
import Testimonials from '../../sections/main/Testimonials';
import FAQ from '../../sections/main/FAQ';
import Footer from '../../sections/main/Footer';

import { GA_EVENTS } from '../../constants/ga-events';

export default function HomePage() {
  const [searchParams] = useSearchParams();

  const [home, setHome] = useState();
  useEffect(() => {
    setHome(searchParams.get('instagram') === 'true' ? 'instagram' : 'basic');
    if (home) {
      ReactGA.send({ hitType: GA_EVENTS.pageview, page: '/home-2', title: 'Home Page 2' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [home]);

  if (!home) {
    return null;
  }

  // eslint-disable-next-line no-constant-condition
  if (true) {
    return <HormoziStyle />;
  }

  return (
    <>
      <Helmet>
        <title> Home | SAT Decoded App </title>
      </Helmet>

      <Header />

      <Grid container spacing={0} flexDirection={'column'} alignItems={'center'}>
        <Hero />
        <Universities />
        <HowItWorks />
        <Stats />

        <Grid item xs={12} sx={{ width: '100%', mt: 10 }}>
          <Pricing />
        </Grid>

        <Grid
          item
          xs={11}
          sx={{
            py: 4,
            width: '100%',
          }}
        >
          <Testimonials />
        </Grid>

        <Grid
          item
          xs={11}
          sx={{
            mt: 10,
            width: '100%',
          }}
        >
          <FAQ />
        </Grid>

        <Grid
          item
          xs={11}
          sx={{
            mt: 15,
            width: '100%',
            background: 'linear-gradient(rgba(22, 28, 36, 0.8), rgba(22, 28, 36, 0.8)) center center',
            borderRadius: '6px',
          }}
        >
          <Join />
        </Grid>

        <Grid item xs={12} sx={{ width: '100%', mt: 10 }}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}
