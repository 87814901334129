import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import {
  Container,
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Stack,
  alpha,
  Button,
  Grow,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

import { AppContext } from '../../../App';

import { useAxios } from '../../../services/axiosService';

// import Ribbon from '../../../sections/common/ribbon';

import Iconify from '../../../components/iconify';
import SATTestHistory from '../../../sections/studentPortal/sat/SATTestHistory';

import { formatDuration } from '../../../utils/formatTime';
import { removeNumbers } from '../../../utils/strings';

function transformValueToPercentage(value) {
  const minValue = 200;
  const maxValue = 800;

  // Ensure the value is within the range
  if (value < minValue) value = minValue;
  if (value > maxValue) value = maxValue;

  // Transform the value to a percentage
  const percentage = ((value - minValue) / (maxValue - minValue)) * 100;
  return percentage;
}

export default function StudentPortalDashboardPage() {
  const { axios } = useAxios();
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  const [dashboard, setDashboard] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      fecthDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fecthDashboard = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(`api/v2/student/dashboard`);
      setDashboard({ ...result.data });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const diagnosticTest = dashboard?.student?.diagnosticTest;
  const lastSATTest = dashboard?.satHistory?.[dashboard?.satHistory?.length - 1]?.report;
  return (
    <>
      <Helmet>
        <title> Statistics | SAT Decoded App </title>
      </Helmet>

      <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 4, gap: 8 }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption" color="text.secondary">
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                Statistics
              </Typography>
            </Typography>
          </Stack>
        </Stack>
        <Card
          sx={{
            position: 'relative',
            flex: 1,
          }}
        >
          <CardContent sx={{ height: '100%' }}>
            {isLoading && (
              <Box
                sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                height={'100%'}
                width="100%"
              >
                <CircularProgress />
              </Box>
            )}
            {!isLoading && (
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  height: '100%',
                }}
              >
                <Stack
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                    width: '100%',
                    gap: 4,
                  }}
                >
                  <Grow in timeout={100}>
                    <Box sx={{ backgroundColor: 'primary.lighter', p: 2, borderRadius: 2 }}>
                      <Typography variant="h3" component={'div'} gutterBottom color="primary.dark">
                        {dashboard?.student?.satTestsTakenCount || 0}
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        display={'flex'}
                        alignItems="center"
                        color="primary.dark"
                      >
                        SAT Tests Taken
                      </Typography>
                    </Box>
                  </Grow>
                  <Grow in timeout={200}>
                    <Box sx={{ backgroundColor: 'warning.lighter', p: 2, borderRadius: 2 }}>
                      <Typography variant="h3" component={'div'} gutterBottom color="warning.dark">
                        {dashboard?.student?.practiceTestsTakenCount || 0}
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        display={'flex'}
                        alignItems="center"
                        color="warning.dark"
                      >
                        Practice Tests Taken
                      </Typography>
                    </Box>
                  </Grow>
                  <Grow in timeout={300}>
                    <Box sx={{ backgroundColor: 'success.lighter', p: 2, borderRadius: 2 }}>
                      <Typography variant="h3" component={'div'} gutterBottom color="success.dark">
                        {(dashboard?.student?.satQuestionsSolved || 0) +
                          (dashboard?.student?.practiceQuestionsSolved || 0)}
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        display={'flex'}
                        alignItems="center"
                        color="success.dark"
                      >
                        Problems Solved
                      </Typography>
                    </Box>
                  </Grow>
                </Stack>

                <Stack
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 4,
                    mt: 4,
                  }}
                >
                  <Grow in timeout={300}>
                    <Stack
                      direction="column"
                      gap={3}
                      alignItems="center"
                      sx={{
                        backgroundColor: (theme) => alpha(theme.palette.violet.lighter, 0.4),
                        // border: (theme) => `1px solid ${theme.palette.primary.main}`,
                        borderRadius: '14px',
                        padding: 3,
                        justifyContent: 'space-between',
                        width: { xs: '100%', sm: '600px' },
                      }}
                    >
                      <Box sx={{ width: '100%' }}>
                        <Typography variant="subtitle1" align="left">
                          SAT Stats
                        </Typography>
                      </Box>
                      {(!diagnosticTest || !lastSATTest) && (
                        <Stack flexDirection={'column'} gap={2} alignItems="center" justifyContent="center">
                          <img src="/assets/icons/no-data.svg" alt="No data" style={{ margin: '0 auto', width: 200 }} />
                        </Stack>
                      )}
                      {!lastSATTest && diagnosticTest && (
                        <Box sx={{ width: '100%' }}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => navigate('/student/practice/sat/instructions')}
                            color="violet"
                            fullWidth
                          >
                            Check your SAT Score
                          </Button>
                          <Typography variant="body2" align="center" color="text.secondary" mt={1} fontSize={12}>
                            Let's start with a full SAT test with 58 questions to get your estimated SAT score.
                          </Typography>
                        </Box>
                      )}
                      {!diagnosticTest && (
                        <Box sx={{ width: '100%' }}>
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => navigate('/student/practice/sat/instructions')}
                            color="violet"
                            fullWidth
                          >
                            Check your level
                          </Button>
                          <Typography variant="body2" align="center" color="text.secondary" mt={1} fontSize={12}>
                            This is a 10 questions test that will help us to understand your current level.
                          </Typography>
                        </Box>
                      )}
                      {lastSATTest && (
                        <Box sx={{ position: 'relative' }}>
                          <Typography
                            variant="body1"
                            align="center"
                            sx={{ position: 'absolute', top: '40px', left: '26px' }}
                          >
                            <strong>{lastSATTest.estimatedSATScoreByExpectedTime || 200}</strong> <br /> SAT Score
                          </Typography>
                          <CircularProgress
                            variant="determinate"
                            value={transformValueToPercentage(lastSATTest.estimatedSATScoreByExpectedTime || 800)}
                            color="violet"
                            size={130}
                            thickness={3}
                          />
                        </Box>
                      )}
                      {lastSATTest && (
                        <Stack
                          sx={{
                            backgroundColor: (theme) => theme.palette.violet.lighter,
                            borderRadius: '14px',
                            padding: 3,
                            justifyContent: 'space-between',
                          }}
                          gap={1}
                        >
                          <Box>
                            <Typography variant="subtitle1">
                              Possible SAT Score: {lastSATTest?.estimatedSATScore || 0}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              SAT Score calculated on actual test duration
                            </Typography>
                          </Box>

                          <Box>
                            <Typography variant="subtitle1">
                              Correct Answers: {lastSATTest?.correctQuestions || 0}/{lastSATTest?.questions || 0}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              You have answered {lastSATTest?.correctQuestions || 0} questions correctly our of{' '}
                              {lastSATTest?.questions || 0}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="subtitle1">
                              Time Taken: {formatDuration(lastSATTest?.totalTime || 0)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              You have taken {formatDuration(lastSATTest?.totalTime || 0)} to complete the test.
                              <br />
                              Expected time was {formatDuration(lastSATTest?.expectedTime || 0)}
                            </Typography>
                          </Box>
                        </Stack>
                      )}
                    </Stack>
                  </Grow>
                  <SATTestHistory data={dashboard?.satHistory?.map((x) => x.report) || []} />
                </Stack>
                <Stack
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 4,
                    mt: 4,
                  }}
                >
                  <Grow in={dashboard?.recentActivity?.length > 0} timeout={1200}>
                    <Stack
                      sx={{
                        backgroundColor: (theme) => alpha(theme.palette.primary.lighter, 0.4),
                        borderRadius: '14px',
                        padding: 3,
                      }}
                    >
                      <Typography variant="subtitle1" align="left" sx={{ mb: 2 }}>
                        Recent Activity
                      </Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="center">Results</TableCell>
                            <TableCell align="left">Time</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dashboard?.recentActivity?.map((activity, index) => {
                            const sat = activity?.sat;

                            return (
                              <TableRow key={index}>
                                <TableCell align="left">
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Iconify
                                      key={index}
                                      icon={sat ? 'ph:exam-bold' : 'eva:file-text-outline'}
                                      sx={{ mr: 1 }}
                                      width={32}
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                      {sat ? 'SAT' : removeNumbers(activity.testName)}
                                      <Typography variant="caption" color="text.secondary" sx={{}}>
                                        {moment(activity?.createdAt).format('DD MMM HH:mm A')}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  {activity?.report?.correctQuestions} / {activity?.report?.questions}
                                  {sat ? ` (${activity?.report?.estimatedSATScoreByExpectedTime})` : ''}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDuration(activity.report.totalTime)} /{' '}
                                  {formatDuration(activity.report.expectedTime)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Stack>
                  </Grow>
                </Stack>
              </Stack>
            )}
          </CardContent>
        </Card>
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ my: 4 }}>
          <Box sx={{ flex: 1 }} />
          <Box>
            <Button
              sx={{ visibility: 'hidden' }}
              // variant="contained"
              // onClick={() => navigate(`/student/practice/${sat ? 'sat' : 'subject'}/session/${queryParams.subject}`)}
              size="large"
              // endIcon={<Iconify icon="eva:arrow-right-outline" width={24} />}
            >
              Start now
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
