// src/Pricing.js
import React from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { Container, Typography, Button, Box, Stack, Divider, useTheme } from '@mui/material';
import { styled } from '@mui/system';

import Iconify from '../../components/iconify';

const plans = [
  {
    icon: 'eva:cube-outline',
    title: 'Trial',
    price: 'Free',
    period: '14 days',
    features: ['14 days free', 'Free SAT diagnostic test', 'Progress Reports', '10 Practice Tests'],
    buttonText: 'Choose Trial',
    buttonVariant: 'outlined',
  },
  {
    icon: 'eva:layers-outline',
    title: 'Premium',
    price: '$50',
    period: 'Monthly',
    features: [
      'Monthly subscription',
      'Free SAT diagnostic test',
      'Progress Reports',
      'Unlimited Practice Tests',
      'Individual study plan',
      'Money Back Guarantee',
    ],
    buttonText: 'Choose Premium',
    buttonVariant: 'contained',
    sx: (theme) => ({
      boxShadow: theme.shadows[4],
      backgroundColor: 'white',
      borderRadius: '6px',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
    }),
  },
];

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  marginTop: 72,
}));

const Pricing = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const sendCTAClick = (free) => {
    ReactGA.event({
      category: 'CTA',
      action: 'click',
      label: `${free ? 'Request Account' : 'Request Account (Paid)'}`,
    });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h3" align="center" gutterBottom>
        Unlock Your Full Potential with <br /> SAT Decoded App
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" paragraph>
        Start Your 14-Day Free Trial Today or Choose a Premium Plan to Get Started
      </Typography>

      <StyledBox>
        {plans.map((plan) => (
          <Stack
            key={plan.title}
            sx={{ display: 'flex', flexDirection: 'column', gap: 5, p: 5, ...(plan.sx ? plan.sx(theme) : undefined) }}
          >
            <Iconify icon={plan.icon} color="info.dark" sx={{ width: 40, height: 40 }} />
            <Stack sx={{ gap: 1 }}>
              <Typography variant="h4" color="textPrimary">
                {plan.title}
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                {plan.period}
              </Typography>
            </Stack>
            <Typography variant="h2">{plan.price}</Typography>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack sx={{ gap: 1 }}>
              {plan.features.map((line) => (
                <Stack key={line} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                  <Iconify icon="eva:checkmark-outline" color="text.secondary" />
                  <Box component={'span'}>{line}</Box>
                </Stack>
              ))}
            </Stack>
            <Button
              fullWidth
              variant={plan.buttonVariant}
              color="primary"
              size="large"
              onClick={() => {
                sendCTAClick(plan.title === 'Trial');
                navigate('/contact');
              }}
            >
              {plan.buttonText}
            </Button>
          </Stack>
        ))}
      </StyledBox>
    </Container>
  );
};

export default Pricing;
