import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// @mui
import { Stack, IconButton, InputAdornment, TextField, FormControl } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useAuth } from '../../../services/auth';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: searchParams.get('email') || '',
    password: searchParams.get('password') || '',
  });

  const handleSubmit = async (e) => {
    if (!formData.email || !formData.password) {
      return;
    }
    e.preventDefault();

    try {
      await auth.login(formData);
      window.location = '/';
    } catch (error) {
      console.error(error);
      const message = error?.response?.data?.message || 'Colud not login';
      alert(message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    // if (searchParams.get('instagram') === 'true') {
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ width: '100%' }}>
          <Stack spacing={3}>
            <TextField name="email" type="email" label="Email address" value={formData.email} onChange={handleChange} />

            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={formData.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Checkbox name="remember" label="Remember me" />
            <Link variant="subtitle2" underline="hover">
              Forgot password?
            </Link>
          </Stack> */}

          <LoadingButton fullWidth size="large" variant="contained" type="submit" sx={{ mt: 4 }}>
            Sign In
          </LoadingButton>
        </FormControl>
      </form>
    </>
  );
}
