import { useEffect } from 'react';
import axios from 'axios';

function authInterceptor(config) {
  const finalConfig = { ...config };
  const token = localStorage.getItem('token');

  if (!finalConfig.headers) {
    finalConfig.headers = {};
  }
  if (token) {
    finalConfig.headers.Authorization = `Bearer ${token}`;
  }

  return finalConfig;
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_PATH}/`,
});

instance.interceptors.request.use(authInterceptor);

export const axiosInstance = instance;

export const CancelSignal = axios.CancelToken.source;

export const isCancelSignal = (e) => axios.isCancel(e);

export const useAxios = () => {
  const signal = CancelSignal();

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      signal.cancel('Unmount useAxios');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { axios: axiosInstance, cancelToken: signal.token };
};
